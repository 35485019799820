.main {
  /* padding: clamp(50px, 10vh, 250px) clamp(20px, 20vh, 30px); */
  padding: 30px 30px;
  line-height: 2rem;
}
.main h1 {
  padding-bottom: 50px;
}
.main ul {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.blog {
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-shadow: 0 0 10px #b9b9b9;
  padding: 20px 10px;
  border-radius: 10px;
  transition: 0.4s ease all;
}
.blog:hover {
  box-shadow: 0 0 10px #898989;
}

/* .left h2 {
  color: var(--dark);
} */
.left p {
  padding: 10px 0;
}
.left div {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 30px;
}
.blog a {
  width: fit-content;
  background: var(--color1);
  padding: 7px 20px;
  border-radius: 5px;
  color: white;
  font-weight: 500;
  outline: 1px solid black;
  transition: 0.1s ease all;
  white-space: nowrap;
  align-self: flex-start;
}
.blog a:hover {
  outline: 2px solid #1761ff;
}
.blog img {
  width: 50px;
}

@media screen and (min-width: 768px) {
  .main {
    padding: 30px 40px;
  }
  .blog {
    gap: 20px;
    padding: 20px;
  }
}

@media screen and (min-width: 992px) {
  .main {
    padding: 30px 50px;
  }
}
