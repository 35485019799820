.main {
  /* padding: clamp(100px, 10vh, 300px) clamp(20px, 5vh, 30px); */
  padding: 30px 30px;
  display: flex;
  flex-direction: column;
  gap: clamp(5vh, 15vh, 20vh);
  padding-bottom: 100px;
}
.sec1 {
  position: relative;
  width: 100%;
  height: 70vh;
  overflow: hidden;
}
.slider .slide img {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  filter: brightness(50%) blur(5px);
}
.contentOverlay {
  position: absolute;
  top: 50%;
  left: 40%;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.sec1 .btn {
  outline: 1px solid white;
  font-size: 1.5rem;
  font-weight: bold;
  padding: 15px;
  width: 250px;
  border-radius: 5px;
  background: var(--color1);
  /* box-shadow: inset -2px -2px 2px white; */
  color: white;
  margin: 20px auto;
  transition: 0.1s ease;
}
.sec1 .btn:hover {
  outline: 2px solid white;
}

/***** Section 2 *****/

.sec2 {
  max-width: 700px;
  margin: 0 auto;
}
.sec2 h2 {
  color: var(--dark);
  font-weight: 400;
  font-size: 20px;
}
.sec2 a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  max-width: 150px;
  transition: 0.2s ease;
  cursor: pointer;
  margin: 20px 0;
}

.sec2 a :nth-child(2) {
  font-size: 30px;
  color: var(--color1);
  font-weight: bold;
}
.sec2 a:hover {
  flex-direction: row-reverse;
  transition: 0.4s ease;
  color: var(--color1);
}

/***** End Section 2 *****/

/***** Section 5 *****/
.sec5 div {
  display: flex;
  justify-content: space-around;
  align-items: center;
  gap: 20px;
  max-width: 700px;
  margin: 0 auto;
}
.sec5 div :nth-child(n) {
  color: black;
  /* font-size: 3rem; */
  font-size: clamp(3rem, 3vw, 5rem);
}
/***** End Section 5 *****/

/***** Section 3 *****/
.sec3 {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
}
.sec3 div:nth-child(1) {
  width: 100%;
  /* height: 400px; */
  height: clamp(300px, 20vw, 600px);
  max-width: 500px;
  overflow: hidden;
  border-radius: 10px;
  transition: 0.4s ease all;
}
.sec3 div:nth-child(1) img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
  transition: 0.4s ease all;
}
.sec3 div:nth-child(1):hover {
  box-shadow: 0 0 10px #8080807d;
}
.sec3 div:nth-child(1) img:hover {
  transform: scale(110%);
}
.sec3 :nth-child(2) {
  display: flex;
  flex-direction: column;
  align-items: start;
  gap: 30px;
}
.sec3 :nth-child(2) h1 {
  font-size: 2rem;
}
.sec3 :nth-child(2) button {
  border: 2px solid var(--color1);
  color: var(--color1);
  padding: 15px 40px;
  border-radius: 50px;
  font-weight: bold;
  transition: 0.4s ease;
}
.sec3 :nth-child(2) button:hover {
  background: var(--color1);
  color: white;
}

/***** End Section 3 *****/

/***** Section 4 *****/
.sec4 {
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.sec4 h1 {
  width: fit-content;
  margin: 0 auto;
  margin-bottom: 30px;
  font-size: 3rem;
}
.gameSlider {
  /* display: flex; */
  /* gap: 20px; */
}
.gameSlider .slide {
  padding: 10px;
}
.next {
  position: absolute;
  right: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}

.prev {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
}
.gameSlider img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

/***** End Section 4 *****/
@media screen and (min-width: 768px) {
  .main {
    /* gap: 10vh; */
  }
  .sec3 div:nth-child(1) {
    width: 70%;
  }
  .sec3 :nth-child(2) h1 {
    font-size: 3rem;
    width: 400px;
  }
}
@media screen and (min-width: 992px) {
  .main {
    /* gap: 15vh; */
    /* padding: 30px 50px; */
    /* padding-bottom: 200px; */
    padding: clamp(100px, 10vh, 300px) 50px;
  }
  /***** Section 1 *****/
  .sec1 {
    height: 90vh;
  }
  /***** End Section 1 *****/

  /***** Section 2 *****/

  .sec2 h2 {
    font-size: 32px;
    line-height: 35px;
  }
  /***** End Section 2 *****/

  /***** Section 3 *****/
  .sec3 {
    display: flex;
    flex-direction: row-reverse;
    gap: 20px;
    align-items: center;
    justify-content: space-around;
  }
  .sec3 div:nth-child(1) {
    width: 40vw;
    max-width: 800px;
  }
  .sec3 :nth-child(2) h1 {
    font-size: 3rem;
    width: 400px;
  }
  /***** End Section 3 *****/

  .sec4 {
    width: 90%;
  }
}
