.main {
  padding: 30px 30px;
}
.main {
  /* text-align: justify; */
  line-height: 2rem;
}
.main h2 {
  margin: 30px 0;
  font-weight: 500;
}
.main h1 {
  padding-bottom: 30px;
  font-weight: 500;
}
.main ul {
  padding-left: clamp(30px, 3vw, 50px);
}
.main p,
.main li {
  color: var(--font1);
}
.main a {
  color: #0298f5;
}
.main a:hover {
  /* color: #024ff5; */
  border-bottom: 1.5px solid #0298f5;
}
.main p strong {
  color: var(--dark);
  /* color: black; */
}
@media screen and (min-width: 992px) {
  .main {
    padding: 30px 50px;
  }
}
