.main {
  /* padding: clamp(50px, 10vh, 250px) clamp(20px, 20vh, 30px); */
  padding: 30px 30px;
  line-height: 2rem;
}
.main h1 {
  padding-bottom: 50px;
}
.sec2 h3 {
  margin: 20px 0;
  font-weight: 500;
}

@media screen and (min-width: 992px) {
  .main {
    padding: 30px 50px;
  }
}
