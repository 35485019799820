:root {
  --dark: #464646;
  --light: #e0e0e0; /*unUsed may be*/
  --font1: #7b7b7b;

  --color1: #44b0ef; /*#ef4f44*/
  --color2: #67bbf0;
}
h1,
h2,
h3 {
  color: var(--dark);
}
p {
  color: var(--font1);
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "roboto";
}
body {
  overflow-x: hidden;
  color: var(--dark);
  background-color: white;
}
a {
  text-decoration: none;
  color: var(--dark);
  cursor: pointer;
}
button {
  cursor: pointer;
  background: none;
  border: none;
}
ul,
li {
  list-style-type: none;
  color: var(--font1);
}

main {
  /* padding: 20px; */
  margin: 0 auto;
  max-width: 1600px;
}

@media screen and (min-width: 992px) {
  main {
    /* padding: 30px; */
  }
}
