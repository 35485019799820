.goTopBtn {
  position: fixed;
  bottom: 50px;
  right: 20px;
  height: 40px;
  width: 40px;
  display: none;
}
.goTopBtnShow {
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
  border-radius: 5px;
  transition: all 0.4s ease;
  font-size: 1.2rem;
  color: white;
  background: #00000081;
  animation: goTop 3s ease-in-out infinite;
  /* box-shadow: inset 0 0 10px var(--color1); */
}
@keyframes goTop {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}
.goTopBtnShow:hover {
  background: var(--color1);
}
