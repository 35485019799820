.footer {
  background: #0f0f19;
  padding: 20px;
  color: var(--light);
  padding: 70px;
  text-align: center;
}
.sec1 a {
  color: white;
  font-weight: bold;
  transition: 0.4s ease;
}
.sec1 a:hover {
  border-bottom: 1px solid var(--color1);
}

.footer h1 {
  color: var(--color1);
  width: fit-content;
  margin: auto;
}

.footer .line {
  height: 1px;
  width: 100%;
  background: var(--light);
}
.footer .sec1 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 50px;
  margin-top: 50px;
}

.sec1 .left div {
  margin-top: 20px;
  line-height: 30px;
  /* padding: 30px 0; */
}
.sec1 .right div {
  padding: 30px 0;
}
.sec1 .mail {
  color: var(--color1);
  font-weight: bold;
  font-size: 1.5rem;
}
.sec1 .tel {
  color: white;
  font-weight: bold;
}

/***** Section 2 *****/

.sec2 div:nth-child(1) {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
}
.sec2 div:nth-child(1) a {
  margin: 50px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  color: var(--color1);
  font-size: 1.5rem;
  border: 1px solid var(--color1);
  border-radius: 10px;
  transition: 0.4s ease;
}
.sec2 div:nth-child(1) a:hover {
  background: #44a2ef5e;
}
.sec2 div:nth-child(2) {
  line-height: 30px;
}

.sec2 div:nth-child(2) a {
  color: white;
}

.sec2 div:nth-child(2) a:hover {
  border-bottom: 1px solid var(--color1);
  color: var(--color1);
}
/***** End Section 2 *****/

@media screen and (min-width: 992px) {
  .footer .logo {
    font-size: 4rem;
  }
  .footer .sec1 {
    flex-direction: row;
    justify-content: space-around;
  }
  .sec1 {
    padding: clamp(50px, 5vh, 300px);
  }
  .left {
    width: 50%;
  }
  .right {
    width: 50%;
  }
  .sec1 .line {
    width: 1px;
    height: 300px;
  }
  .sec1 .mail {
    font-size: 2rem;
  }
  .sec2 div:nth-child(2) {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
  }
}
