.main {
  padding: 30px 30px;
  line-height: 2rem;
}
.main .logoTitle {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.main .logoTitle img {
  width: 50px;
}

/***** description *****/

.gameDesc section {
  display: flex;
  flex-direction: column;
  gap: 15px;
}
.gameDesc h3 {
  width: fit-content;
  margin: 20px 0;
}

.gameDesc ul {
  padding-left: 50px;
}
.gameDesc ul li {
  list-style-type: disc;
}

/* .gameDesc ul li::before {
  content: "∘     ";
} */

/***** End description *****/

.gameSlider {
  /* border: 2px solid black; */
  max-width: clamp(250px, 90vw, 800px);
  margin: 0 auto;
  margin-bottom: 100px;
  border-radius: 10px;
  max-height: 800px;
}

.gameSlider .slide {
  /* border: 2px solid red; */
  margin: 0 auto;
  padding: 10px;
}
.gameSlider .slide img {
  max-width: 100%;
  margin: 0 auto;
  border-radius: 10px;
  max-height: 550px;
  object-fit: contain;
  /* border: 2px solid black; */
}

@media screen and (min-width: 576px) {
  .gameSlider {
    max-width: clamp(400px, 70vw, 900px);
  }
}
@media screen and (min-width: 768px) {
  .gameSlider {
    max-width: clamp(300px, 70vw, 600px);
  }
  .gameDesc h3 {
    margin: 50px 0 20px;
    /* margin-top: 20px;
    margin-bottom: 20px; */
  }
}
@media screen and (min-width: 992px) {
  .gameSlider {
    max-width: clamp(500px, 70vw, 900px);
  }
  .main {
    padding: 30px 50px;
  }
  .main .logoTitle {
    margin-bottom: 50px;
  }
}
