/***** nav *****/ /***** End nav *****/
.navContainer {
  transition: all 0.4s ease;
  z-index: 10;
  box-shadow: 0px 3px 10px #80808080;
}
.nav {
  max-width: 1600px;
  margin: 0 auto;
  background: white;
  transition: 0.4s ease-in-out;
  /* padding: 10px 30px; */
}

.logobar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 30px;
}
.logo img {
  width: 40px;
}
.navMenu {
  height: 0px;
  overflow: hidden;
  transition: 0.4s ease;
}
.navMenu li a {
  padding: 20px 20px;
  display: block;
  color: var(--dark);
  text-align: center;
  font-size: 1.3rem;
  font-weight: 500;

  letter-spacing: 2px;
}

.navMenu li a.active {
  color: var(--color1);
  /* text-shadow: 1px 1px 0 #0000007d, -1px -1px 0 #0000007d, -1px 1px 0 #0000007d,
    1px -1px 0 #0000007d; */
}
.navMenu li a:hover {
  background: #3584d414;
}
.navMenuShow {
  height: 220px;
  margin-top: 0px;
}
.menubarIcon {
  display: block;
  font-size: 2rem;
  color: var(--dark);
}

@media screen and (min-width: 992px) {
  .logo img {
    width: 50px;
  }
  .navContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    /* position: absolute; */
    width: 100%;
    /* box-shadow: none; */
    top: 0;
  }
  .navContainerShow {
    /* position: fixed; */
    position: sticky;
    top: 0;
    width: 100%;
    background: white;
  }
  .nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: none;
    padding: 10px 50px;
  }
  .logobar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--dark);
    padding: 0 0;
  }
  .navMenu {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 50px;
    height: auto;
    /* padding: 0 30px; */
    /* width: 230px; */
  }
  .navMenuShow {
    margin: 0;
  }
  .navMenu li {
    padding: 0;
  }
  .navMenu li a {
    padding: 0;
    display: block;
    border: none;
    color: var(--dark);
    font-size: 18px;
    font-weight: 500;
  }
  .navMenu li a:hover {
    background: none;
  }
  .menubarIcon {
    display: none;
  }
}

/***** Section *****/ /***** End Section *****/
